body,
html {
  height: 100%;
  overflow: hidden;
  background-color: black;
}

#root {
  height: 100%;
}

.App {
  .main {
    max-width: 400px;
    margin: auto;
  }

  .question {
    margin-bottom: 0;

    h1 {
      margin: 0;
    }
  }

  .Footer {
    // border-bottom: 42px solid black;

    .Sheet {
      margin: 0;
    }
  }

  .Filter {
    height: 100%;
    margin-bottom: 0;
  }

  .Filter .Table {
    height: calc(100% - 56px);
    margin-bottom: 0;

    td:nth-child(3) {
      width: 25%;
    }

    td:nth-child(4) {
      width: 50%;
    }
  }
}
